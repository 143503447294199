// Imports


// Plugins
const plugins = [{
  location: "/Users/blick/Documents/workspace/ant-fi/node_modules/react-static-plugin-typescript",
  plugins: [],
  hooks: {}
},
{
  location: "/Users/blick/Documents/workspace/ant-fi/node_modules/react-static-plugin-styled-components",
  plugins: [],
  hooks: {}
},
{
  location: "/Users/blick/Documents/workspace/ant-fi",
  plugins: [],
  hooks: {}
}]

// Export em!
export default plugins