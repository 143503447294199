import { Header } from './header';
import { Footer } from './footer';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

const Container = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 144em;
  box-sizing: border-box;
  padding-left: 0;
  padding-right: 0;
  
  p {
    font-size: 1.6em;
    line-height: ${2.8/1.6}em;
    margin-bottom: ${1.5/1.6}em;
    
    &:last-child {
      margin-bottom: 0;
    }
  }
  
  ${breakpoint('md')`
    padding-left: 1.5em;
    padding-right: 1.5em;
    
    p {
      font-size: 1.8em;
      line-height: ${2.8/1.8}em;
      margin-bottom: ${1.5/1.8}em;
    }
  `}
  
  ${breakpoint('lg')`
    padding-left: 3em;
    padding-right: 3em;
  `}
`;

const InnerContainer = styled.div`
  padding-left: 1.5em;
  padding-right: 1.5em;
  
  ${breakpoint('sm')`
    padding-left: 3em;
    padding-right: 3em;
  `}
  
  ${breakpoint('lg')`
    padding-left: 6em;
    padding-right: 6em;
  `}
  
  ${breakpoint('xl')`
    padding-left: 9em;
    padding-right: 9em;
  `}
`;

const Hero = styled.div`
  width: 100%;
  
  .hero-text {
    margin-top: 2em;
    padding-left: 1em;
    padding-right: 1em;
    text-align: center;
    
    p {
      font-weight: 400;
      font-size: 1.8em;  
    }
  }
  
  ${breakpoint('lg')`
    padding-bottom: 4em;
    
    .hero-text {
      margin-top: 3em;
      margin-bottom: 4em;
      
      p {
        font-size: 2.1em;
      }
    }
  `}
`;

const HeroImage = styled.figure`
  padding-top: 45%;
  background: url("/images/hero.jpg");
  background-size: cover;
`;

export {
  Header,
  Footer,
  Hero,
  HeroImage,
  Container,
  InnerContainer
}