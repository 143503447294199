import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

export const Header = styled.header`
  position: relative;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  padding-top: 1.5em;
  padding-bottom: 1.5em;
  z-index: 999;
  
  .logo {
    img {
      width: 70%;
    }
  }
  
  nav {
    display: none;
  }
  
  ${breakpoint('sm')`
    .logo {
      img {
        width: 100%;
      }
    }
  `}
  
  ${breakpoint('md')`
    position: sticky;
    justify-content: space-between;
    nav {
      display: block;
      
      a {
        font-size: 1.4em;
        color: black;
        text-decoration: none;
        text-transform: uppercase;
        padding: ${1/1.4}em;
        font-weight: 600;
      }
    }
  `}
`;