import React from 'react'
import { Root, Routes } from 'react-static'
import { Link } from '@reach/router'
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import { Header, Footer, Container, InnerContainer, Hero, HeroImage } from './styles';
import './styles/reset.css';

const GlobalStyle = createGlobalStyle`
  body {
    font-size: 62.5%;
    text-size-adjust: 100%;
    text-rendering: optimizeLegibility;
    -webkit-tap-highlight-color: transparent;
    color: black;
    font-family: 'Poppins', sans-serif;
  }
  
  a, button {
    cursor: pointer;
    text-decoration: none;
    color: #de5c12;
    font-weight: 600;
  }
  
  b, strong {
    font-weight: 600;
  }
  
  ul.list {
    padding: 0;
    li {
      display: flex;
      align-items: center;
      margin-bottom: 1em;
      &:before {
        content: '';
        display: inline-block;
        margin-right: 2em;
        width: 5px;
        height: 5px;
        border-radius: 2.5px;
        background-color: #de5c12;
      }
      
      > * {
        display: inline-block;
      }
    }
  }
  
  h2 {
    display: flex;
    align-items: center;
    font-size: 2.4em;
    font-weight: 500;
    margin-bottom: ${3/2.4}em;
    
    &:before {
      content: '';
      display: inline-block;
      margin-right: 0.5em;
      width: 1.5em;
      height: 0.1em;
      background-color: #de5c12;
    }
  }
  
  h3 {
    font-size: 1.8em;
    line-height: ${2.8/1.8}em;
    font-weight: 400;
  }
  
  .content-section {
    margin-top: 3em;
    margin-bottom: 3em;
    padding-top: 2em;
    padding-bottom: 2em;
    
    a {
      color: #de5c12;
      font-weight: 500;
    }
  }
  
  .slick-dots li button:before {
    color: #de5c12 !important;
    font-size: 10px;
  }
`;

const theme = {
  breakpoints: {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200
  }
};

function App() {
  return (
    <React.Fragment>
    <GlobalStyle/>
    <Root scrollToHashOffset={-100}>
      <ThemeProvider theme={theme}>
        <Container>
          <Header>
            <figure className="logo">
              <img src={'/images/ant-logo.png'} srcSet={`${'/images/ant-logo.png'} 1x, ${'/images/ant-logo@2x.png'} 2x`} />
            </figure>
            <nav>
              <Link to="/#yritys">Yritys</Link>
              <Link to="/#palvelut">Palvelut</Link>
              <Link to="/#asiakkaamme">Asiakkaamme</Link>
              <Link to="/#referenssit">Referenssit</Link>
              <Link to="/#yhteystiedot">Yhteystiedot</Link>
            </nav>
          </Header>
          <Hero>
            <HeroImage />
            <div className={'hero-text'}>
              <p>Tarvitsetko luotettavaa ja ammattimaista remontointipalvelua?</p>
              <p>Ota rohkeasti yhteyttä ja pyydä ilmainen tarjous meiltä!</p>
            </div>
          </Hero>
          <InnerContainer>
            <Routes />
          </InnerContainer>
          <Footer>
            <a href={'mailto:nt@ammattisaneeraus.fi'}>nt@ammattisaneeraus.fi</a>
            <a href={'https://www.sertifikaattihaku.fi/certificates/C-23559-23-17'} target={'_blank'}>Märkätilojen vedeneristäjä C-23559-23-17</a>
            <span>Y-tunnus 2928585-5</span>
          </Footer>
        </Container>
      </ThemeProvider>
    </Root>
    </React.Fragment>
  )
}

export default App
