import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

export const Footer = styled.footer`
  display: flex;
  flex-direction: column;
  font-weight: 600;
  font-size: 1.6em;
  padding-top: ${5/1.6}em;
  padding-bottom: ${5/1.6}em;
  padding-right: ${3/1.6}em;
  padding-left: ${3/1.6}em;
  border-top: 2px solid black;
  
  > * {
    margin-top: 2em;
    
    &:first-child {
      margin-top: 0;
    }
  }
  
  ${breakpoint('lg')`
    justify-content: space-between;
    flex-direction: row;
    
    > * {
      margin-top: 0;
    }
  `}
`;